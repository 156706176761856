import { template as template_0ed1c993e69149e786646e1f8d4a6430 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0ed1c993e69149e786646e1f8d4a6430(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
