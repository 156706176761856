import { template as template_16006f89ffe6420aaa12246e1edde1ba } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_16006f89ffe6420aaa12246e1edde1ba(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
