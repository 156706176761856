import { template as template_e592412198c347958465158a7430c6b5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e592412198c347958465158a7430c6b5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
