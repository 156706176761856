import { template as template_83c98f67a812453796c2c4901500fe86 } from "@ember/template-compiler";
const FKText = template_83c98f67a812453796c2c4901500fe86(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
