import { template as template_c1b8e0c359c744209ca7a8902c03558d } from "@ember/template-compiler";
const SidebarSectionMessage = template_c1b8e0c359c744209ca7a8902c03558d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
